import React, {useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';

import {Card} from "./posts/card";

const Item = props => {
  const { children } = props;
  const ref = useRef(null)

  useEffect(() => {
    if (!ref?.current) return;

    const images = ref.current.querySelectorAll('img');
    const image_count = images.length;
    let load_count = 0;
    const showItem = () => ref.current.classList.replace('opacity-0', 'opacity-1');
    const handleLoad = () => {
      load_count += 1;
      if (load_count === image_count) showItem();
    }

    if (image_count !== 0) {
      images.forEach(img => {
        if (img.complete) { handleLoad() }
        else img.addEventListener('load', handleLoad)
      })
    } else showItem();
  }, []);

  return <div ref={ref} className='opacity-0 transition-all duration-500 ease-in-out'>
    {children}
  </div>;
}

export const Collection = props => {
  const { posts, options } = props;

  return (
    <div className="social_web-collection transition-opacity gap-14 flex flex-col w-full">
      {posts.map(post => <Item key={post.id}>
        <Card post={post} />
      </Item>)}
    </div>
  )
}
