import React from 'react';
import ReactDOM from 'react-dom';
import {useNavigate} from "react-router-dom";
import {Locked} from "../users/locked";
import {Card} from "../layouts/card";
import { Form as FormLayout } from "../layouts/form";
import { Field} from "../layouts/forms/field";
import {Button} from "../layouts/button";
import {useAPI} from "../../contexts/api_context";

export const Form = props => {
  const { image } = props;
  const { API } = useAPI()

  const navigate = useNavigate();

  const submit = async evt => {
    evt.preventDefault();
    const form = evt.target;
    const formData = new FormData(form);
    image ?
        API.Posts.update(image, formData).then(image => navigate(`/posts/${image.id}`)) :
        API.Posts.create(formData).then(image => navigate(`/posts/${image.id}`))
  }

  return <div className="p-6">
      <FormLayout onSubmit={submit} encType="multipart/form-data" className="bg-white w-full content-between">
        <Field type="hidden" name="id" value={image?.id} />
        <Field type="file" label="File" name="files" multiple={true} />
        <Field type="textarea" label="Body" name="body" rows={10} defaultValue={image?.body} />
        <Button type="submit" label="Create Image" />
      </FormLayout>
    </div>
}
