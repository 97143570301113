import React, {useState} from 'react';
import {useAuth} from "../../../contexts/auth_context";
import {Card} from "../../layouts/card";
import {Form} from "../../layouts/form";
import {Field} from "../../layouts/forms/field";
import {Button} from "../../layouts/button";
import {Link, useNavigate} from "react-router-dom";
import {buildFlash} from "../../flash";
import {useAPI} from "../../../contexts/api_context";

export const NotificationsSettings = props => {
  const { account, user, setUser } = useAuth();
  const navigate = useNavigate();
  const { API } = useAPI()

  const [enableEmail, setEnableEmail] = useState(!!account?.settings?.email_notifications);
  const [enableTxt, setEnableTxt] = useState(!!account?.settings?.txt_notifications);

  const onSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);

    return API.MySettings.update({ user: formData }).
      then(setUser).
      then(() => navigate('/my/settings', { state: { flash: buildFlash('Settings saved.', 'success') } }))
  }

  return (
    <Card title="Notifications">
      <Form onSubmit={onSubmit} method="PUT">
        <div className="flex">
          {!enableEmail && <Field type="hidden" name="email_notifications" value="" />}
          <Field type="checkbox" label={"Email"} name="email_notifications" defaultChecked={enableEmail} onChange={() => setEnableEmail((!enableEmail))} value={user?.email || ''} />
          <span className="ml-auto italic text-gray-400">{user.email}</span>
        </div>
        <div className="flex">
          {!enableTxt && <Field type="hidden" name="txt_notifications" value="" />}
          <Field type="checkbox" disabled={!user.phone?.length} label={"Txt"} name="txt_notifications" onChange={() => setEnableTxt((!enableTxt))} defaultChecked={enableTxt} value={user?.phone || ''} />
          <span className="ml-auto italic text-gray-400">
            {user.phone || <span>
              <Link to={'/my/account'} className="text-blue-400">
                Add a phone number
              </Link>
            </span>}
          </span>
        </div>
        <Button type="button" label="Save" />
      </Form>
    </Card>
  )
}
