import React, {useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {useParams} from "react-router-dom";
import {useAPI} from "../../contexts/api_context";

export const Loader = props => {
  const { imageID } = useParams();
  const { API } = useAPI()
  const { render } = props;
  const [image, setImage] = useState();

  useEffect(() => {
    API.Posts.getById(imageID, { threads: { levels: 1 }}).then(setImage)
  }, []);

  return image ? render(image) : null;
}
