import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import {useAuth} from "../../contexts/auth_context";

export const Locked = props => {
  const { children } = props;
  const { user } = useAuth();

  const [locked, setLocked] = useState(!user);

  const lock = () => setLocked(true);
  const unlock = () => setLocked(false);

  useEffect(() => user ? unlock() : lock(), [user?.id])

  return !locked && children;
}
