import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';

const ArrowLeft = props => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="-translate-x-0.5 h-5 w-5 md:w-8 md:h-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
</svg>;

const ArrowRight = props => <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-5 w-5 md:w-8 md:h-8">
  <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
</svg>;

const buildImageSrcSet = file => {
  const { mobile, tablet, desktop } = file.variants;

  return `${mobile.url} 500w, ${tablet.url} 800w, ${desktop.url} 1200w`;
}

const MultipleImages = props => {
  const { image } = props;

  const carouselRef = useRef();
  const imageRefs = useRef([]);
  const [index, setIndex] = useState(0);
  const [loadingCount, setLoadingCount] = useState(0)

  const modIndex = i => {
    const length = image.files.length;
    const remain = i % length;
    const newIndex = Math.floor(remain >= 0 ? remain : remain + length);
    return newIndex;
  }

  const update = i => {
    const newIndex = modIndex(i);
    setIndex(newIndex);
  }

  const onIntersecting = useCallback(
    entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting && loadingCount === imageRefs.current.length) {
          const newIndex = (imageRefs.current).indexOf(entry.target);
          update(newIndex);
        }
      })
    }, [imageRefs.current.length, loadingCount]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(onIntersecting, {
      root: carouselRef.current,
      threshold: 0.6
    });

    imageRefs.current.filter(img => img !== undefined).forEach(img => {
      observer.observe(img);
    });

    return function cleanup() {
      observer.disconnect();
    };
  }, [image.files.length, imageRefs.current.length, loadingCount]);

  const onClick = (i) => {
    const newIndex = modIndex(i);
    imageRefs.current[newIndex].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  return (
    <div className="relative overflow-hidden">
      <div className="flex scroll-smooth overflow-x-auto snap-x touch-pan-x" ref={carouselRef}>
        {image.files.map((file, i) => <div key={file.id} className="snap-x snap-center inline rounded-t shrink-0 w-full" data-slide-index={i}>
          <div key={file.id} >
            <img src={file.url} className="inline" onLoad={() => setLoadingCount(loadingCount + 1)} srcSet={buildImageSrcSet(file)} ref={img => (imageRefs.current[i] = img)} />
          </div>
        </div>)}
      </div>
      {index !== 0 && <button type="button" onClick={() => onClick(index - 1)} className="z-10 absolute bottom-10 left-6 bg-white/40 rounded-full p-1 flex items-center justify-center border border-black/50">
        <ArrowLeft />
      </button>}
      {index !== image.files.length - 1 && <button type="button" onClick={() => onClick(index + 1)} className="z-10 absolute bottom-10 right-6 bg-white/40 rounded-full p-1 flex items-center justify-center border-black/50">
        <ArrowRight />
      </button>}
    </div>
  )
}

export const Content = props => {
  const { image } = props;

  return (
    <>
      <div className="border-b border-gray-600">
        {
          image.files.length === 1 ?
            <img src={image.files[0].url} className="rounded-t w-full" srcSet={buildImageSrcSet(image.files[0])} /> :
            <MultipleImages image={image} />
        }
      </div>
      {
        image.body.length > 0 &&
        <div className="px-4 mt-4">
          <div className="text-justify social_web--text--body" dangerouslySetInnerHTML={{__html: `${image.body}`}}/>
        </div>
      }
    </>
  )
}
