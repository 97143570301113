import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Outlet, useLocation} from "react-router-dom";

import ReactModal from "react-modal";
import {AuthProvider} from "../contexts/auth_context";
import {APIProvider} from "../contexts/api_context";
import {Router} from "./router";
import {ConfigProvider} from "../contexts/config_context";



export const App = props => {
  const { config = {} } = props;

  ReactModal.setAppElement('#app');

  return (
    <ConfigProvider config={Sozi.Config}>
      <APIProvider>
        <AuthProvider>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </AuthProvider>
      </APIProvider>
    </ConfigProvider>
  )
}
