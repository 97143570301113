import React from 'react';

import {useAuth} from "../../contexts/auth_context";
import {Field} from "../layouts/forms/field";
import {Form} from "../layouts/form";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "../layouts/button";
import {Card as CardLayout} from "../layouts/card";
import {useAPI} from "../../contexts/api_context";

export const SignIn = props => {
  const { onSignIn } = props;
  const { setUser } = useAuth();
  const navigate = useNavigate();
  const { API } = useAPI()

  const onSubmit = evt => {
    evt.preventDefault();

    const {
      email: { value: email },
      password: { value: password }
    } = evt.target.elements;

    return API.UserSessions.
      create({ email, password }).
      then((user) => setUser(user)).
      then(user => {
        if (onSignIn) { onSignIn(user) }
        navigate('/')
      });
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Field name="email" type="text" label="Email" />
        <Field name="password" type="password" autoComplete="current-password" label="Password" />
        <Button type="submit" label="Sign In" />
      </Form>
    </>
  );
}
