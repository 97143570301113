import React, {useState} from 'react';
import {useAuth} from "../../../contexts/auth_context";
import {Card} from "../../layouts/card";
import {Form} from "../../layouts/form";
import {Field} from "../../layouts/forms/field";
import {Button} from "../../layouts/button";
import {useNavigate} from "react-router-dom";
import {buildFlash} from "../../flash";
import {useAPI} from "../../../contexts/api_context";

const PasswordSettings = props => {
  const { user } = useAuth();
  const [update, setUpdate] = useState(false);
  const { API } = useAPI()

  const onSubmit = evt => {
    evt.preventDefault();

    const { target: form } = evt;
    const formData = new FormData(form)

    return API.MyPassword.update({ user: formData }).
      then(() => form.reset()).
      then(() => navigate('/my/settings', { state: { flash: buildFlash('Account saved.', 'success') } }));
  }

  const onKeyUp = evt => {
    setUpdate(!!evt.target.value)
  }

  return (
    <Card title="Change Password">
      <Form onSubmit={onSubmit}>
        <Field type="hidden" autoComplete="username" name="username" value={user?.username} />
        <div>
          <Field label="Current password" onKeyUp={onKeyUp} autoComplete="password" type="password" name="current_password" note="Enter your current password to allow creating a new password" />
        </div>
        <div>
          <Field label="New password" autoComplete="new-password" type="password" name="password" disabled={!update} />
        </div>
        <div>
          <Field label="Confirm new password" autoComplete="new-password" type="password" name="password_confirmation" disabled={!update} />
        </div>
        <Button type="submit" label="Save"/>
      </Form>
    </Card>
  )
}

export const AccountSettings = props => {
  const { user, setUser } = useAuth();
  const navigate = useNavigate();
  const { API } = useAPI()

  const onSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);

    return API.MyAccount.update({ user: formData }).
      then(setUser).
      then(() => navigate('/my/settings', { state: { flash: buildFlash('Account saved.', 'success') } }));
  }

  return (
    <>
      <Card className="mb-6" title='Account Settings' note="Settings for communication and authentication">
          <Form onSubmit={onSubmit} method="PUT">
            <div>
              <Field type="text" autoComplete="email" label="Email" name="email" value={user?.email} />
            </div>
            <div>
              <Field type="text" autoComplete="tel" label="Phone" name="phone" value={user?.phone} />
            </div>
            <Button type="button" label="Save" />
        </Form>
      </Card>
      <PasswordSettings />
    </>
  )
}
