import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';

import { Comments } from "./dashboard/comments";
import {Card} from "./card";
import {Locked} from "../users/locked";
import {Form as CommentForm} from "../comments/form";
import {Card as CardLayout} from "../layouts/card";
import { useConfig } from "../../contexts/config_context";

export const Dashboard = props => {
  const [post, setPost] = useState(props.post);
  const [comments, setComments] = useState(post.comments);
  const { config } = useConfig()

  useEffect(() => {
    if (post.files.length > 0) {
      const file = post.files[0];
      const { tablet } = file.variants;
      document.querySelector('head').insertAdjacentHTML(
        'beforeend',
        `

        <!-- Facebook Meta Tags -->
        <meta property="og:title" content="${config.title}" />
        <meta property="og:type" content="website">
        <meta property="og:url" content="https://${config.canonical_hostname}/posts/${post.id}" />
        <meta property="og:image" content="${tablet.url}" />
        <meta property="og:image:secure_url" content="${tablet.url}" />

        <!-- Twitter Meta Tags -->
        <meta name="twitter:card" content="summary_large_image">
        <meta property="twitter:domain" content="chessie.dog">
        <meta property="twitter:url" content="https://${config.canonical_hostname}/posts/${post.id}">
        <meta name="twitter:title" content="Chessie">
        <meta name="twitter:image" content="${tablet.url}">
        <meta name="twitter:image:secure_url" content="${tablet.url}">

      `
      )
    }
  }, [])

  const addComment = comment => setComments([comment, ...comments]);

  return (
    <div className="flex flex-col w-full gap-9">
      <Card post={post} onComment={addComment} />
      <Locked>
        <CardLayout padding={false}>
          <CommentForm commentable={post} variant={'expandable'} onCreate={addComment} />
        </CardLayout>
        <Comments commentable={post} comments={comments} setComments={setComments} />
      </Locked>
    </div>
  )
}
