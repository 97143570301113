import React, {useEffect, useRef, useState} from 'react';
import {Card as CardLayout} from "./layouts/card";
import {FailureIcon} from "./icons/failure";
import {InfoIcon} from "./icons/info";
import {SuccessIcon} from "./icons/success";

const typeToColorMap = {
  success: 'bg-green-500',
  info: 'bg-blue-500',
  failure: 'bg-red-500'
}

const typeToIconMap = {
  failure: <FailureIcon />,
  info: <InfoIcon />,
  success: <SuccessIcon />
}

export const buildFlash = (message, type='info') => ({ type, message });

export const Flash = props => {
  const { flash } = props;

  const flashes = typeof flash === 'object' ? [flash] : flash;


  return (
    <div>
      {flashes.map(({type, message}, i) => <CardLayout key={i} className="shadow-md mt-5 text-white" backgroundColor={typeToColorMap[type]} padding={false}>
        <div className='px-4 py-2 flex items-center'>
          <span className="mr-2">{typeToIconMap[type]}</span>
          {message}
        </div>
      </CardLayout>)}
    </div>
  )
}
