import React from "react";

export const Content = props => {
  const { comment } = props;

  return (
    <div className="social_web--text--body break-words" dangerouslySetInnerHTML={{__html: comment.body_html}}>
    </div>
  )
}
