import React, {useEffect, useRef, useState} from "react";
import ReactDOM from 'react-dom';
import {usePopper} from "react-popper";
import {useAuth} from "../../contexts/auth_context";
import {HeartIcon} from "../icons/heart";
import {useAPI} from "../../contexts/api_context";
import {destroy} from "../../../../sozi/api/reactions_api";

const emojiButton = <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
</svg>


const emojiToHeroiconMap = {
  'heart': <HeartIcon />
}

export const Button = props => {
  const { post, options } = props;
  const { account } = useAuth()
  const [reactions, setReactions] = useState(post.reactions);
  const [userReaction, setUserReaction] = useState(post.current_reaction)
  const trigger = useRef();
  const { API } = useAPI()

  const react = (emoji) => {
    const previousUserReaction = userReaction
    setUserReaction({ kind: emoji })

    const req = userReaction
      ? API.Reactions.destroy({ id: userReaction.id })
      : API.Reactions.create({ reactable_type: post.type, reactable_id: post.id, kind: emoji });

    return req.then(setUserReaction).catch(() => setUserReaction(previousUserReaction))
  }

  const unReact = () => API.Reactions
    .destroy({ id: userReaction.id }).
    then(() => {
        setUserReaction(null)
    })

  const countForkind = kind => reactions.filter(reaction => reaction.kind === kind).length;

  const handleReact = kind => {
    if (userReaction?.kind === kind) unReact();
    else react(kind);
  }

  return (
    <button type="button" className="text-lg translate-y-px leading-6" ref={trigger} onClick={() => handleReact('heart')}>
      <div className="size-6">
        <HeartIcon className={userReaction?.kind === 'heart' ? 'first:fill-red-400 first:text-red-600' : 'first:fill-white'} />
        </div>
    </button>
  )
}
