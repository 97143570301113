import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import {
  Routes,
  Route,
  useLocation,
  Navigate, useNavigate
} from "react-router-dom";
import { Front} from "./front"
import { Form as ImageForm } from "./images/form";
import { Loader as ImageLoader } from "./images/loader";
import { Dashboard as PostDashboard } from "./posts/dashboard";
import {Collection} from "./collection";
import { Loader as PostsLoader} from "./posts/loader"
import {SignUp} from "./users/sign_up";
import {SignIn} from "./users/sign_in";
import {useAuth} from "../contexts/auth_context";
import {Layout} from "./layout";
import {NotificationsSettings, ProfileSettings, Index} from "./users/settings";
import {Form as PostForm} from "./posts/form";
import {AccountSettings} from "./users/settings/account";
import {buildFlash} from "./flash";
import {useAPI} from "../contexts/api_context";

const RequireAuth = ({ children }) => {
  let { user, refreshUser } = useAuth();
  let location = useLocation();
  const { API } = useAPI()

  if (!user) return <Navigate to="/" state={{ from: location, flash: buildFlash('Please sign in.', 'failure') }} replace />;

  return children;
}

export const Router = props => {
  const navigate = useNavigate();
  const location = useLocation();
  const { API } = useAPI()

  useEffect(() => {
    window.history.replaceState(null, '')
  }, [location?.state])

  return (
    <Routes>
      <Route path={'/'} element={<Layout />}>
        <Route exact path={'/'} index element={<Front />} />
        <Route exact path={'/posts'} element={ <PostsLoader url={API.Posts.query} render={posts => <Collection posts={posts} options={{ truncate: true }} />} />} />
        <Route exact path={'/posts/:imageID'} element={<ImageLoader render={image => <PostDashboard post={image} />} />} />
        <Route exact path={'/posts/:imageID/edit'} element={<ImageLoader render={image => <ImageForm image={image} />} />} />
        <Route exact path={'/posts/new'} element={<ImageForm />} />
        <Route exact path={'/posts/new'} element={<PostForm />} />
        <Route exact path={'/my/settings'} element={<RequireAuth><Index /></RequireAuth>} />
        <Route exact path={'/my/settings/notifications'} element={<RequireAuth><NotificationsSettings /></RequireAuth>} />
        <Route exact path={'/my/profile'} element={<RequireAuth><ProfileSettings /></RequireAuth>} />
        <Route exact path={'/my/account'} element={<RequireAuth><AccountSettings /></RequireAuth>} />
        <Route exact path={'/sign-up'} element={<SignUp />} />
        <Route exact path={'/sign-in'} element={<SignIn onSignIn={() => navigate('/')} />} />
      </Route>
    </Routes>
  )
}
