import React, {useState} from 'react';

const ConfigContext = React.createContext({});

function ConfigProvider(props) {
  const [config, setConfig] = useState(props.config || {});

  return (
    <ConfigContext.Provider value={{ config, setConfig }} {...props} />
  )
}

const useConfig = () => React.useContext(ConfigContext)

export {ConfigProvider, useConfig}
