import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

import { Collection} from "./collection";
import {useAPI} from "../contexts/api_context";
import {useAuth} from "../contexts/auth_context";

export const Front = props => {
  const { API } = useAPI()
  const { account } = useAuth()
  const [pinned, setPinned] = useState([])
  const [all, setAll] = useState([])

  useEffect(() => {
    API.Posts.query({ per_page: 10, page: 0, by: { parent_id: '\x00' } }).
      then(({ items }) => setAll(items));
  }, []);

  return (
    <>
      <article className="w-full">
        {pinned.length > 0 && <>
        <h2 className="text-4xl font-bold">Pinned</h2>
        <Collection posts={pinned}/>
        </>}

        {all.length > 0 && <Collection posts={all} options={{ truncate: false }}/>}
      </article>
    </>
  )
}
