import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import { Form as CommentForm } from "../../comments/form";
import { Card as CommentCard } from "../../comments/card";
import {useAuth} from "../../../contexts/auth_context";
import {Locked} from "../../users/locked";
import {Card as CardLayout} from "../../layouts/card";

export const Comments = props => {
  const { commentable, comments, setComments } = props;

  const [formExpanded, setFormExpanded] = useState(false);

  const blurTarget = useRef()

  const onCreate = comment => {
    setComments([comment, ...comments]);
  }

  const onUpdate = comment => {
    const index = comments.findIndex(element => element.id === comment.id)
    if (~index) {
      const newComments = comments;
      newComments[index] = comment;
      setComments(newComments);
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-y-6">
        {
          comments.map(comment =>
            <CardLayout key={comment.id} padding={false}>
              <CommentCard commentable={commentable} comment={comment} onUpdate={onUpdate} />
            </CardLayout>)
        }
      </div>
    </>
  );
}
