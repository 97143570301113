import React, { useEffect, useState } from 'react'



const AuthContext = React.createContext({})

function AuthProvider (props) {
  let userFromLocalStorage = window.localStorage.getItem('user')
  if (userFromLocalStorage === 'undefined') { userFromLocalStorage = null }
  const [user, setUser] = useState(JSON.parse(userFromLocalStorage))

  let accountFromLocalStorage = window.localStorage.getItem('account')
  if (accountFromLocalStorage === 'undefined') { accountFromLocalStorage = null }
  const [account, setAccount] = useState(JSON.parse(accountFromLocalStorage))

  const refreshUser = () => {
    console.debug('Loading user from server')
    Sozi.API.UserSessions.refresh().then((user) => {
      console.debug('Loaded user from server', user)
      window.localStorage.setItem('user', JSON.stringify(user))
      setUser(user)
      if (user?.account) {
        window.localStorage.setItem('account', JSON.stringify(user.account))
        setAccount(user.account)
      }
    })
  }

  useEffect(() => {
    refreshUser()
  }, [])

  useEffect(() => {
    // Unset the user when the HTTP client raises an UnauthenticatedError
    window.onunhandledrejection = (evt) => {
      if (evt.reason.name === 'UnauthenticatedError') {
        console.debug('Handling unhandled UnauthenticatedError')
        clearUser()
        clearAccount()
      } else throw (evt)
    }
  }, [])

  const clearUser = () => {
    window.localStorage.removeItem('user')
    setUser(null)
    clearAccount()
  }

  const clearAccount = () => {
    window.localStorage.removeItem('account')
    setAccount(null)
  }

  const cacheUser = (user) => {
    console.debug('Caching user', user)
    window.localStorage.setItem('user', JSON.stringify(user))
    if (user) {
      setUser(user)
      cacheAccount(user.account)
    } else clearUser()
  }

  const cacheAccount = (acct) => {
    console.debug('Caching account', acct)
    window.localStorage.setItem('account', JSON.stringify(acct))
    setAccount(acct)
  }

  const signOut = () => Sozi.API.UserSessions.destroy().then(() => clearUser())

  return (
      <AuthContext.Provider value={{ refreshUser, user, setUser: cacheUser, account, setAccount: cacheAccount, signOut }} {...props} />
  )
}

const useAuth = () => React.useContext(AuthContext)

export { AuthProvider, useAuth }
