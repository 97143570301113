import React from 'react';
import ReactDOM from 'react-dom';

import { Link } from 'react-router-dom';
import {useConfig} from "../contexts/config_context";
import ChessieStencil from '../assets/images/chessie-stencil.png';

export const Nav = props => {
  const { config } = useConfig();
  return (
    <nav className="div px-6 py-3 sticky bg-white top-0 flex justify-center items-center border-b-2 border-black z-10">
      <div className="flex items-center">
        <h1 className="uppercase tracking-widest text-5xl sm:text-6xl md:text-6xl" style={{ backgroundColor: 'white', display: 'inline-block', color: 'black', fontFamily: "'Dosis', sans-serif" }}>
          <Link to="/">
            <div className="flex items-center">
              <img src={`/assets/${ChessieStencil}`} className="h-14 mr-4" />
              Chessie
            </div>
          </Link>
        </h1>
      </div>
    </nav>
  )
}
