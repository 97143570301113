import React from 'react';
import ReactDOM from 'react-dom';

import {App} from "./components/app";
import { createRoot } from "react-dom/client";
import ChessieCircle from './assets/images/chessie-circle.png'

document.querySelector('head').insertAdjacentHTML(
  'afterbegin',
  `
    <link href="https://fonts.googleapis.com" rel="preconnect"/>
    <link crossorigin="" href="https://fonts.gstatic.com" rel="preconnect"/>
    <link href="https://fonts.googleapis.com/css2?family=Dosis:wght@600&amp;display=swap" rel="stylesheet"/>
    <link href="https://fonts.googleapis.com/css?family=Nunito&amp;subset=latin,latin-ext" rel="stylesheet" type="text/css"/>
    <link rel="apple-touch-icon" href="/assets/${ChessieCircle}">
    <link rel="shortcut icon" type="image/png" href="/assets/${ChessieCircle}"/>
  `
)

document.addEventListener('DOMContentLoaded', () => {
  const container = document.querySelector('#app')
  const root = createRoot(container)
  const config = JSON.parse(container.dataset.soziConfig)
  const setting = JSON.parse(container.dataset.soziSettings)
  root.render(<App config={config} setting={setting} />)

})
