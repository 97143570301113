import React from 'react';
import ReactDOM from 'react-dom';

export const Card = props => {
  const { children, note, title, padding = true, backgroundColor = 'bg-white' } = props;
  const className = props.className || '';

  return (
    <div className={`${className} ${backgroundColor} rounded-md shadow-lg shadow-black/50 border-2 border-black overflow-hidden ${padding ? 'p-6' : ''} flex flex-col gap-4`}>
      {title && <div>
        <h2 className="text-2xl font-black">{title}</h2>
        {note && <p className="text-gray-400">{note}</p>}
      </div>}
      {children}
    </div>
  )

}
