import React from 'react';

export const LockIcon = props => {
  const { ...attrs } = props;

  return <span {...attrs}>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24"
         stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
            d="M15 7a2 2 0 012 2m4 0a6 6 0 01-7.743 5.743L11 17H9v2H7v2H4a1 1 0 01-1-1v-2.586a1 1 0 01.293-.707l5.964-5.964A6 6 0 1121 9z"/>
    </svg>
  </span>;
}

