import React, {useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export const Loader = props => {
  const { url, render } = props;
  const [posts, setPosts] = useState(null);

  // useEffect(() => get({ url }).then(setPosts), []);

  return posts != null ? render(posts) : null;
}
