import React, {useState} from 'react';
import {Field} from "../layouts/forms/field";
import {Button} from "../layouts/button";

export const Fields = props => {
  const { image } = props;

  return (
    <>
      <Field type="hidden" name="id" value={image?.id} />
      <Field type="file" label="File" name="files" multiple={true} />
      <Field type="textarea" label="Body" name="body" rows={10} defaultValue={image?.body} />
    </>
  )
}
