import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {Form as FormLayout} from "../layouts/form";
import {Button} from "../layouts/button";
import {Content as ImageContent} from "../images/content";
import {Content as CommentContent} from "../comments/content";
import {Fields as ImageFields } from "../images/fields";
import {ImageIcon} from "../icons/image";
import {VideoIcon} from "../icons/video";
import {useAPI} from "../../contexts/api_context";

const TYPES = [
  'Sozi::Post'
];

export const Form = props => {
  const { post } = props;
  const { API } = useAPI()
  const [type, setType] = useState(post?.type || null);

  const navigate = useNavigate();

  const submit = async evt => {
    evt.preventDefault();
    const form = evt.target;
    const formData = new FormData(form);
    post?.id ?
        API.Posts.update(post, formData).then(_post => navigate('/')) :
        API.Posts.create(formData).then(_post => navigate('/'))
  }

  const renderIcon = type => {
    switch(type) {
      case 'Sozi::Post': return <ImageIcon />
    }
  }

  const renderFields = type => {
    switch(type) {
      case 'Sozi::Post': return <ImageFields image={post} />
      default: {
        throw 'Unexpected post type'
      }
    }
  }

  const renderName = type => {
    switch(type) {
      case 'Sozi::Post': return 'Image'
      default: {
        throw 'Unexpected post type'
      }
    }
  }

  const selectType = t => {
    if (t === type) setType(null)
    else setType(t)
  }

  return (
    <FormLayout onSubmit={submit} encType="multipart/form-data" className="bg-white w-full content-between">
      {type && (
        <>
          <input type="hidden" name="type" value={type} />
          {renderFields(type)}
          <Button type="submit" label={`Create ${renderName(type)}`} />
        </>
      )}
    </FormLayout>
  )
}
