import React, {useState} from 'react';
import {useAuth} from "../../../contexts/auth_context";
import {Card} from "../../layouts/card";
import {Form} from "../../layouts/form";
import {Field} from "../../layouts/forms/field";
import {Button} from "../../layouts/button";
import {Avatar} from "../avatar";
import {useNavigate} from "react-router-dom";
import {buildFlash} from "../../flash";
import {useAPI} from "../../../contexts/api_context";

export const ProfileSettings = props => {
  const { account, setAccount } = useAuth();
  const navigate = useNavigate();
  const { API } = useAPI()

  const onSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target)

    return API.MyAccount.
      update({ account: formData }).
      then(console.log).
      then(() => navigate('/my/settings', { state: { flash: buildFlash('Profile saved', 'success') } }));
  }

  return (
    <Card title="Profile" note="Settings for your shared information">
      <Form onSubmit={onSubmit}>
        <div>
          <Field type="text" autoComplete="off" label="Username" name="username" value={account?.username} />
        </div>
        <Button type="submit" label="Save"/>
      </Form>
    </Card>
  )
}
