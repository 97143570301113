import React, {useState} from 'react';

export const Form = props => {
  const { className, children, method, onSubmit: incomingOnSubmit = async () => {} } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const onSubmit =  evt => {
    // Show 'loading' indicator if form is slow to submit
    const toggleSubmitting = setTimeout(() => {
      setIsSubmitting(true);
    }, 200);

    incomingOnSubmit(evt).then(() => clearTimeout(toggleSubmitting));
  }

  return (
    <form className={`flex flex-col gap-6 ${className} ${isSubmitting ? 'opacity-25' : ''}`} method={method || 'POST'} onSubmit={onSubmit}>
      {children}
    </form>
  )
}
