import React, {useState} from 'react';
import ReactDOM from 'react-dom';

import {useAuth} from "../../contexts/auth_context";
import {useNavigate} from "react-router-dom";
import {Form} from "../layouts/form";
import {Card} from "../layouts/card";
import {Field} from "../layouts/forms/field";
import {useAPI} from "../../contexts/api_context";

const NotificationSettings = props => {
  const [showEmail, setShowEmail] = useState(false);
  const [showTxt, setShowTxt] = useState(false);

  return (
    <fieldset>
      <div className="mb-4">
        <legend className="text-lg font-bold">
          Notification Settings
        </legend>

        <p className="text-sm text-gray-500">
          Receive notifications of new posts.
        </p>
      </div>

      <ul className="flex flex-col gap-6">
        <li>
          <label className="flex mb-2">
            <input type="checkbox" className="formInput w-5 h-5 mr-2" onClick={() => setShowEmail(!showEmail)} />
            Email
          </label>
          <input type="email" disabled={!showEmail} name="user[settings][email_notifications]" className="border w-full p-2" />
        </li>
        <li>
          <label className="flex mb-2">
            <input type="checkbox" className="formInput w-5 h-5 mr-2" onClick={() => setShowTxt(!showTxt)} />
            TXT
          </label>
          <input type="phone" disabled={!showTxt} name="user[settings][txt_notifications]" className="border w-full p-2" />
        </li>
      </ul>
    </fieldset>
  )
}

export const SignUp = props => {
  const { setUser } = useAuth();
  const { API } = useAPI()
  const navigate = useNavigate()

  const onSubmit = evt => {
    evt.preventDefault();

    const {
      email: { value: email },
      username: { value: username },
      password: { value: password },
      password_confirmation: { value: password_confirmation }
    } = evt.target.elements;

    return API.UserRegistrations.create({ email, username, password, password_confirmation }).
      then(setUser).
      then(() => navigate('/'));
  }

  return (
    <Form className="flex flex-col gap-6" method="POST" onSubmit={onSubmit}>
      <Field type="text" name="email" label="Email" />
      <Field type="text" name="username" label="Username" />
      <Field type="password" name="password" label="Password" />
      <Field type="password" name="password_confirmation" label="Password confirmation" />
      <div>
        <button type="submit" className="border w-full p-2 bg-black text-white">Go!</button>
      </div>
    </Form>
  );
}
