import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const avatarThemes = [
  ["bg-red-500", "border-red-700", "text-white"],
  ["bg-blue-400", "border-lime-400", "text-white"]
];

const randomTheme = () => avatarThemes[Math.floor(Math.random() * avatarThemes.length)];

export const Avatar = props => {
  const { user, size } = props;
  const [expanded, setExpanded] = useState(!!props.expanded);

  const sizeMap = {
    'sm': 'h-5 w-5',
    'md': 'h-10 w-10',
    'lg': 'h-20 w-20'
  }

  return (
    <>
      <div className={`${sizeMap[size || 'md']} select-none text-xs text-center items-center flex flex-col justify-center ${!user && randomTheme().join(' ') && 'rounded-full border-2'}`}>
        {user.avatar ? <img src={user.avatar.url} draggable={false} className={`w-8 select-none`} /> : <span>{user.username[0].toUpperCase()}</span>}
      </div>
      { expanded && <div className="flex">
        <span className="font-black mr-2">{user.username}</span>
      </div>}
    </>
  )
}
