import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { usePopper} from "react-popper";

import { Link} from "react-router-dom";

import { Avatar} from "../users/avatar";
import {Content} from "./content";
import { Button as ReactionButton } from "../reactions/button";
import {Locked} from "../users/locked";
import { Card as CardLayout} from "../layouts/card";
import { CommentIcon } from "../icons/comment";
import { ClockIcon} from "../icons/clock";
import {WithModal} from "../layouts/modal";
import {Form as CommentForm} from "../comments/form";
import {EditIcon} from "../icons/edit";
import {useAuth} from "../../contexts/auth_context";
import {Form} from "../images/form";

export const Top = props => {
  const { post } = props;

  return (
  <div className="flex whitespace-nowrap">
    <div className="flex mr-auto items-center">
      <div className="mr-4">
        <Avatar user={{}} />
      </div>
      <div className="flex flex-col">
        <span className="font-bold text-sm mr-auto text-ellipsis flex-shrink">shanecav</span>
      </div>
    </div>
  </div>
  )
}

export const Card = props => {
  const { post, onComment, onReact } = props;
  const { user } = useAuth()

  const onCommentCreate = ({ setShowModal, comment }) => {
    if (onComment) onComment(comment);
    setShowModal(false);
  }

  return (
    <CardLayout padding={false}>
      <div className='w-full bg-white pb-4'>
        <div className="flex flex-col justify-around">
          <div className="flex-grow mb-4">
            <Content post={post} options={{ truncate: false }} />
          </div>
          <div className="flex items-center px-4 text-gray-600">
            <div className="mr-auto">
              <Locked>
                <div className="flex items-center gap-4">
                  <div className="flex">
                    <ReactionButton post={post} />
                  </div>

                  <span className="font-medium">
                    <WithModal title={'Add Comment'} button={<div className="size-6"><CommentIcon /></div>}>
                      {({ setShowModal }) => <>
                        <CommentForm className="border border-black rounded" commentable={post} expanded={true} onCreate={comment => onCommentCreate({ setShowModal, comment })} />
                      </>}
                    </WithModal>
                  </span>
                </div>
              </Locked>
            </div>


            <div className="flex items-center gap-6">
              {user?.id == post.author.id &&
                <WithModal button={<div className="size-6"><EditIcon /></div>} title="Edit Post">
                  {({ setShowModal }) => <Form image={post} /> }
                </WithModal>}
              <div>
                <Link to={`/posts/${post.id}`} className="flex items-center gap-1">
                  <div className="size-6"><ClockIcon /></div>
                  {post.created_at_fuzzy}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CardLayout>
  )
}
