import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import {Card as CardLayout} from "../layouts/card";
import {Field} from "../layouts/forms/field";
import {Button} from "../layouts/button";
import { Form as FormLayout} from "../layouts/form";
import {useAPI} from "../../contexts/api_context";
import {Card} from "../posts/card";
import {Content} from "../posts/content";

const OneLine = props => {
  const { comment, commentable, onSubmit } = props;
  const [expanded, setExpanded] = useState(props.expanded);
  const ref = useRef(null);

  const heightClass = expanded ? 'h-40' : 'h-10';
  const paddingClass = expanded ? 'px-2' : 'px-2';

  useEffect(() => {
    const toggleExpanded = evt => {
      if (!ref?.current) return;

      const isWithinForm = evt.target.isEqualNode(ref.current) || ref.current.contains(evt.target);
      setExpanded(isWithinForm);
    }

    document.addEventListener('click', toggleExpanded);

    return () => document.removeEventListener('click', toggleExpanded);
  }, [ref?.current])

  const submit = evt => {
    onSubmit(evt).
      then(() => {
        setExpanded(false)
    })
  }

  useEffect(() => {
    if (expanded) {
      const timeout = setTimeout(() => ref.current.scrollIntoView({ behavior: 'smooth' }), 100)

      return () => clearTimeout(timeout)
    }
  }, [expanded])

  return (
    <form onSubmit={submit} ref={ref} className={paddingClass}>
        <Field type="hidden" name="parent_id" value={commentable.id} />
      <Field
        type="textarea"
        name="body"
        placeholder="Comment..."
        className={`focus-visible:outline-none transition-all resize-none ${heightClass}`}
        defaultValue={comment?.body_raw}
        border={false}
        rows={1}
      />
      { expanded && (
        <div className="flex justify-end w-full py-2">
          <Button type="submit" label={`${comment ? 'Edit' : 'Add'} Comment`} />
        </div>
      )}
    </form>
  )
}

export const  Form = React.memo(props => {
    const { API } = useAPI()
  const { commentable, comment, onUpdate, onCreate, variant } = props;
  const [expanded, setExpanded] = useState(!!props.expanded)
  const textArea = useRef(null);

  const submit = evt => {
    evt.preventDefault();
    const form = evt.target;
    const formData = new FormData(form);
    return comment ?
      API.Posts.
        update(comment, formData).
        then(comment => {
          form.reset();
          setExpanded(false);
          onUpdate(comment)
        }) :
      API.Posts.
        create(formData).
        then(comment => {
          setExpanded(false)
          form.reset();
          if (onCreate) onCreate(comment);
        });
  }

  return (
    variant === 'expandable' ? <OneLine expanded={expanded} comment={comment} commentable={commentable} onSubmit={submit} /> :
        <div className="flex flex-col gap-6">
            <div className="rounded-b"><Content post={commentable} /></div>
    <FormLayout onSubmit={submit} className="flex flex-col items-center w-full relative overflow-hidden">
      <Field type="hidden" name="parent_id" value={commentable.id} />
      <div className="grow w-full">
        <Field
          type="textarea"
          name="body"
          placeholder="Comment..."
          defaultValue={comment?.body_raw}
          rows={4}
        />
      </div>
      <div className="flex justify-end w-full py-2">
        <Button type="submit" label={`${comment ? 'Edit' : 'Add'} Comment`} />
      </div>
    </FormLayout>
        </div>
  )
})
