import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {Content as ImageContent} from "../images/content";
import {Content as CommentContent} from "../comments/content";

export const Content = props => {
  const { post, options } = props;

  return <ImageContent image={post} />
}
