import ReactModal from "react-modal";
import React, {useEffect, useState} from "react";
import { Card as CardLayout } from "./card";
import { CloseIcon } from "../icons/close";

export const ModalLayout = props => {
  const { setShowModal, showModal, title } = props;

  const [contentRef, setContentRef] = useState(null);

  useEffect(() => {
    const closeModalWhenEscape = evt => {
      if (evt.code !== "Escape") return;
      setShowModal(false);
    }

    document.addEventListener('keydown', closeModalWhenEscape)

    return () => {
      document.removeEventListener('keydown', closeModalWhenEscape)
    };
  }, [contentRef, showModal])

  return <ReactModal
    shouldFocusAfterRender={false}
    isOpen={showModal}
    contentRef={setContentRef}
    preventScroll={true}
    className="p-6 w-full flex justify-center "
    style={{
      overlay: {
        zIndex: 40,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(0, 0, 0, 0.3)'
      }
    }}
  >
    <CardLayout className="relative p-6 w-full h-full z-40 md:max-w-xl">
      <div className="flex w-full justify-between items-center mb-3">
        {title && <h1 className="font-bold text-lg">{title}</h1>}
        <button onClick={() => setShowModal(false)} className="ml-auto size-6">
          <CloseIcon />
        </button>
      </div>
      <div>
        <div>
          {props.children}
        </div>
      </div>
    </CardLayout>
  </ReactModal>;
}

export const WithModal = props => {
  const { children, button, title } = props;

  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button className="block" type="button" onClick={() => setShowModal(true)}>
        {button}
      </button>
      <ModalLayout title={title} showModal={showModal} setShowModal={setShowModal}>
        {children({setShowModal})}
      </ModalLayout>
    </>
  )
}
