import React from 'react';

export const Button = props => {
  const { label } = props;
  return (
    <button type="submit" className="border rounded-lg py-2 px-3 bg-black text-white">
      {label}
    </button>
  )
}
