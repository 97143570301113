import React, {useEffect, useRef, useState} from 'react';
import ReactDOM from 'react-dom';
import {SignIn} from "./sign_in";
import {SignUp} from "./sign_up";
import {Link} from "react-router-dom";

export const AuthCard = props => {
  const { message, onSignIn } = props;

  const [showSignIn, setShowSignIn] = useState(true)

  return (
    <div className="">
      { message && <h2 className="text-center mb-2">{message}</h2> }
      <div className="flex flex-col justify-around">
        {showSignIn ? <><h1 className="text-3xl mb-4">Sign In</h1>
        <SignIn onSignIn={onSignIn} />
        </>:
          <><h1 className="text-3xl mb-4">Sign Up</h1><SignUp />
          </>}
        {showSignIn ?
            <p className="text-center mt-4">
              Or
              {' '}
              <button className="text-blue-400" onClick={() => setShowSignIn(false)}>sign up</button>.
            </p> :
          <p className="text-center mt-4">
            Or
            {' '}
            <button className="text-blue-400" onClick={() => setShowSignIn(true)}>sign in</button>.
          </p>}
      </div>
    </div>
  )
}
