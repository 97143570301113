import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {Avatar} from "../users/avatar";
import {Link} from "react-router-dom";
import { Form } from "../comments/form";
import { Content } from "../comments/content";
import {Button as ReactionButton} from "../reactions/button";
import {Locked} from "../users/locked";
import {useAuth} from "../../contexts/auth_context";
import { EditIcon} from "../icons/edit";
import { CloseIcon} from "../icons/close";
import {WithModal} from "../layouts/modal";

const editButton = <span className="flex justify-content-around items-center size-8">
  <EditIcon className="mr-2" />
</span>;

const Header = props => {
  const { comment, commentable, user, onUpdate } = props;

  return (
    <div className="flex relative mb-4 whitespace-nowrap items-center">
      <div className="flex mr-auto items-center">
        <div className={`mr-4 select-none items-start flex items-center`}>
          <div className="inline-block rounded-full h-5 w-5 bg-black mr-4"></div>
          <span className="font-black mr-2">{comment.author.username}</span>
        </div>
      </div>
      {user && user.id === comment.author.id &&
        <div className="relative">
          <WithModal button={editButton} title="Edit Comment">
            {({ setShowModal }) => <Form commentable={commentable} comment={comment} onUpdate={comment => onUpdate({ comment, setShowModal })}/> }
          </WithModal>
        </div>
      }
    </div>
  )
}

export const Card = props => {
  const { commentable, onUpdate: onUpdateCallback } = props;

  const { user } = useAuth();

  const [comment, setComment] = useState(props.comment);

  const [showForm, setShowForm] = useState(false);

  const onUpdate = ({ comment, setShowModal }) => {
    onUpdateCallback(comment);
    setShowModal(false);
    setComment(comment);
  }

  return (
    <div className="flex flex-col bg-white p-4">
      <Header comment={comment} commentable={commentable} user={user} onUpdate={onUpdate} />
      <div>
        <div className="text-justify mb-4">
          <Content comment={comment} />
        </div>
        <div className="flex items-center text-sm">
          <div className="mr-auto">
            <Locked>
              <div className="flex items-center">
                <div className="mr-3 flex">
                  <ReactionButton post={comment} />
                </div>
              </div>
            </Locked>
          </div>
          <span className="text-gray-400">{comment.created_at_fuzzy}</span>
        </div>
      </div>
    </div>
  );
}
