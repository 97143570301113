import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';

const APIContext = React.createContext({});


const APIProvider = props => {
  return (
    <APIContext.Provider value={{ API: Sozi.API }} {...props} />
  )
}

const useAPI = () => React.useContext(APIContext)

export {APIProvider, useAPI}
