import React, {useState} from 'react';
import {SettingsIcon} from "../../icons/settings";
import {Form} from "../../layouts/form";
import {Field} from "../../layouts/forms/field";
import {useAuth} from "../../../contexts/auth_context";
import {Card} from "../../layouts/card";
import {ProfileIcon} from "../../icons/profile";
import {NotificationsIcon} from "../../icons/notifications";
import {Link, useNavigate} from "react-router-dom";
import {Avatar} from "../avatar";
import {Button} from "../../layouts/button";
import {AccountIcon} from "../../icons/account";
import {NotificationsSettings} from "./notifications";
import {ProfileSettings} from "./profile";
import { PlusIcon } from "../../icons/plus";

export {
  NotificationsSettings,
  ProfileSettings
}

const NotificationField = props => {
  const { label, name } = props;

  const [enableInput, setEnableInput] = useState(false);

  const toggle = () => setEnableInput(!enableInput);

  return (
    <>
      <Field type="checkbox" label={label} onChange={toggle} />
      <Field type="text" name={name} disabled={!enableInput} placeholder={label} />
    </>
  )
}

const SettingsLink = ({ Icon, to, label, note}) => {
  return <Link className="w-full flex items-center p-4 hover:bg-gray-200" to={to}>
    {<Icon className="mr-4" />}
    <span>{label}</span>
    {note && <div className="ml-auto italic text-gray-400">{note}</div>}
  </Link>
}

export const Index = props => {
  const { user, signOut } = useAuth();

  return (
    <div className="flex flex-col w-full gap-10">
      {user.admin && <Card padding={false}>
        <Link to="/posts/new" className="flex items-center w-full p-4">
          <PlusIcon className="mr-4" />
          Add Post
        </Link>
      </Card>}

      <Card padding={false}>
        <ul className="w-full">
          <li className="flex items-center border-b-2 border-black">
            <SettingsLink Icon={AccountIcon} to={'/my/account'} label={"Account"} note={"password, email"} />
          </li>
          <li className="flex items-center border-b-2 border-black">
            <SettingsLink Icon={ProfileIcon} to={'/my/profile'} label={"Profile"} note={"username, bio"} />
          </li>
          <li className="flex items-center border-black">
            <SettingsLink Icon={NotificationsIcon} to={'/my/settings/notifications'} label={"Notifications"} note={"email, txt"} />
          </li>
        </ul>
      </Card>
      <div className="text-center mt-20">
        <button type="button" className="text-blue-400" onClick={() => signOut()}>
          Sign Out
        </button>
      </div>
    </div>
  );
}
